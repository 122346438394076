import Typography from "typography";
import grandViewTheme from "typography-theme-grand-view";

const typography = new Typography({
  ...grandViewTheme,
  overrideThemeStyles: () => ({
    "h1,h2,h3,h4,h5,h6": {
      color: "unset",
      marginTop: 0,
    },
  }),
});

// Export helper functions
export const { scale, rhythm, options } = typography;
export default typography;
