/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react";
import PropTypes from "prop-types";
import styled, { ThemeProvider } from "styled-components";

import ThemeToggle from "./ThemeToggle";
import { lightTheme, darkTheme } from "./themes";
import media from "styled-media-query";
import { rhythm } from "../utils/typography";

const StyledLayout = styled.div`
  padding: 10% 10% 0% 10%;
  background-color: ${props => props.theme.background};
  transition: color, background-color 0.25s ease-out;
  color: ${props => props.theme.primary};

  ${media.lessThan("medium")`
    padding: 5%;
  `}

  h1,h2,h3,h4,h5,h6,p {
    color: ${props => props.theme.primary};
  }
`;

const StyledFooter = styled.footer`
  color: ${props => props.theme.secondary};
  opacity: 0.6;
  display: flex;
  padding: ${rhythm(1)};
  justify-content: center;
  align-items: center;
`;

const Layout = ({ children }) => {
  const [dark, setDark] = useState(false);

  return (
    <ThemeProvider theme={dark ? darkTheme : lightTheme}>
      <ThemeToggle dark={dark} setDark={setDark} />
      <StyledLayout>
        <main>{children}</main>
        <StyledFooter>© {new Date().getFullYear()} Oskari Liukku</StyledFooter>
      </StyledLayout>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
