import React from "react";
import styled from "styled-components";
import { rhythm } from "../utils/typography";

const StyledToggle = styled.button`
  background: none;
  border: none;
  position: fixed;
  top: ${rhythm(1)};
  right: ${rhythm(1)};
`;

const ThemeToggle = ({ dark, setDark }) => {
  return (
    <StyledToggle onClick={() => setDark(!dark)}>
      <span role="img" aria-label="sheep">
        {dark ? "☀️" : "🌙"}
      </span>
    </StyledToggle>
  );
};

export default ThemeToggle;
