export const lightTheme = {
  isDark: false,
  primary: "#272D2D",
  secondary: "#66666E",
  background: "#FFFCF7",
};

export const darkTheme = {
  isDark: true,
  primary: "#EAF2EF",
  secondary: "#8F8F95",
  background: "#02080D",
};
